#home-main-div {
  display: flex;
  flex-direction: column;
  margin-top: 10%;
  margin-right: 10%;
  margin-left: 10%;
  height: 100vh;
}

#intro-div {
  margin-top: 1%;
  margin-right: 35%;
  font-size: 1.5rem;
  line-height: 2.8rem;
}

#author-name {
  font-size: 15vh;
  color: rgba(255, 255, 255, 0.944);
}
