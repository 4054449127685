#main-projects-div {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  height: 100vh;
}

#projects-intro {
  margin-top: 2%;
  margin-right: 10%;
  margin-left: 10%;
  text-align: center;
}

#projects-intro h2 {
  font-size: 5vh;
  margin-bottom: 3%;
}

#projects-p {
  margin-top: 2%;
  margin-right: 15%;
  margin-left: 20%;
  margin-bottom: 5%;
}

.project,
.project img {
  border-radius: 50%;
  margin-right: 6rem;
  margin-left: 2rem;
  height: 14rem;
  width: 15rem;
}

.project img {
  box-shadow: 0.2rem 1rem 1.8rem 0.5rem black;
}

.project.bottom {
  margin-bottom: 20%;
}

@keyframes effect {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1.3, 0.6);
  }

  50% {
    transform: scale(1.1, 0.9);
  }
  100% {
    transform: scale(1, 1);
  }
}

.img-zoom {
  transition: transform 0.3s;
}

.img-zoom:hover {
  transform: scale(1.2);
}
